<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    title="添加注册码"
    width="60%"
    @close="close"
  >
    <el-form
      ref="formRef"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="数量" prop="num">
        <el-input
          v-model="form.num"
          place-holder="请输入需要生成的注册码数量"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
  import { addRegisterCode } from '@/api/hostApply'

  export default defineComponent({
    name: 'AddRegisterCode',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        rules: {
          num: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入需要生成的注册码数量',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
        formRef: null,
        form: {
          num: 1,
        },
      })

      const showEdit = async () => {
        state.dialogFormVisible = true
      }

      const close = () => {
        state['formRef'].resetFields()
        state.form.num = 1
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            await addRegisterCode(state.form)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }

      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
